@import "../assets/icons/all.min.css";
@import "~react-base-table/styles.css";
.react-vis-magic-css-import-rule {
  display: inherit;
}
.rv-treemap {
  font-size: 12px;
  position: relative;
}
.rv-treemap__leaf {
  overflow: hidden;
  position: absolute;
}
.rv-treemap__leaf--circle {
  align-items: center;
  border-radius: 100%;
  display: flex;
  justify-content: center;
}
.rv-treemap__leaf__content {
  overflow: hidden;
  padding: 10px;
  text-overflow: ellipsis;
}
.rv-xy-plot {
  color: #c3c3c3;
  position: relative;
}
.rv-xy-plot canvas {
  pointer-events: none;
}
.rv-xy-plot .rv-xy-canvas {
  pointer-events: none;
  position: absolute;
}
.rv-xy-plot__inner {
  display: block;
}
.rv-xy-plot__axis__line {
  fill: none;
  stroke-width: 2px;
  stroke: #e6e6e9;
}
.rv-xy-plot__axis__tick__line {
  stroke: #e6e6e9;
}
.rv-xy-plot__axis__tick__text {
  fill: #6b6b76;
  font-size: 11px;
}
.rv-xy-plot__axis__title text {
  fill: #6b6b76;
  font-size: 11px;
}
.rv-xy-plot__grid-lines__line {
  stroke: #e6e6e9;
}
.rv-xy-plot__circular-grid-lines__line {
  fill-opacity: 0;
  stroke: #e6e6e9;
}
.rv-xy-plot__series,
.rv-xy-plot__series path {
  pointer-events: all;
}
.rv-xy-plot__series--line {
  fill: none;
  stroke: #000;
  stroke-width: 2px;
}
.rv-crosshair {
  position: absolute;
  font-size: 11px;
  pointer-events: none;
}
.rv-crosshair__line {
  background: #47d3d9;
  width: 1px;
}
.rv-crosshair__inner {
  position: absolute;
  text-align: left;
  top: 0;
}
.rv-crosshair__inner__content {
  border-radius: 4px;
  background: #3a3a48;
  color: #fff;
  font-size: 12px;
  padding: 7px 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
}
.rv-crosshair__inner--left {
  right: 4px;
}
.rv-crosshair__inner--right {
  left: 4px;
}
.rv-crosshair__title {
  font-weight: bold;
  white-space: nowrap;
}
.rv-crosshair__item {
  white-space: nowrap;
}
.rv-hint {
  position: absolute;
  pointer-events: none;
}
.rv-hint__content {
  border-radius: 4px;
  padding: 7px 10px;
  font-size: 12px;
  background: #3a3a48;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
  color: #fff;
  text-align: left;
  white-space: nowrap;
}
.rv-discrete-color-legend {
  box-sizing: border-box;
  overflow-y: auto;
  font-size: 12px;
}
.rv-discrete-color-legend.horizontal {
  white-space: nowrap;
}
.rv-discrete-color-legend-item {
  color: #3a3a48;
  border-radius: 1px;
  padding: 9px 10px;
}
.rv-discrete-color-legend-item.horizontal {
  display: inline-block;
}
.rv-discrete-color-legend-item.horizontal .rv-discrete-color-legend-item__title {
  margin-left: 0;
  display: block;
}
.rv-discrete-color-legend-item__color {
  display: inline-block;
  vertical-align: middle;
  overflow: visible;
}
.rv-discrete-color-legend-item__color__path {
  stroke: #dcdcdc;
  stroke-width: 2px;
}
.rv-discrete-color-legend-item__title {
  margin-left: 10px;
}
.rv-discrete-color-legend-item.disabled {
  color: #b8b8b8;
}
.rv-discrete-color-legend-item.clickable {
  cursor: pointer;
}
.rv-discrete-color-legend-item.clickable:hover {
  background: #f9f9f9;
}
.rv-search-wrapper {
  display: flex;
  flex-direction: column;
}
.rv-search-wrapper__form {
  flex: 0;
}
.rv-search-wrapper__form__input {
  width: 100%;
  color: #a6a6a5;
  border: 1px solid #e5e5e4;
  padding: 7px 10px;
  font-size: 12px;
  box-sizing: border-box;
  border-radius: 2px;
  margin: 0 0 9px;
  outline: 0;
}
.rv-search-wrapper__contents {
  flex: 1;
  overflow: auto;
}
.rv-continuous-color-legend {
  font-size: 12px;
}
.rv-continuous-color-legend .rv-gradient {
  height: 4px;
  border-radius: 2px;
  margin-bottom: 5px;
}
.rv-continuous-size-legend {
  font-size: 12px;
}
.rv-continuous-size-legend .rv-bubbles {
  text-align: justify;
  overflow: hidden;
  margin-bottom: 5px;
  width: 100%;
}
.rv-continuous-size-legend .rv-bubble {
  background: #d8d9dc;
  display: inline-block;
  vertical-align: bottom;
}
.rv-continuous-size-legend .rv-spacer {
  display: inline-block;
  font-size: 0;
  line-height: 0;
  width: 100%;
}
.rv-legend-titles {
  height: 16px;
  position: relative;
}
.rv-legend-titles__left,
.rv-legend-titles__right,
.rv-legend-titles__center {
  position: absolute;
  white-space: nowrap;
  overflow: hidden;
}
.rv-legend-titles__center {
  display: block;
  text-align: center;
  width: 100%;
}
.rv-legend-titles__right {
  right: 0;
}
.rv-radial-chart .rv-xy-plot__series--label {
  pointer-events: none;
}
body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  user-select: none;
  -webkit-user-select: none;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
body {
  padding-top: constant(safe-area-inset-top);
  padding-top: env(safe-area-inset-top);
}
body,
#root,
html {
  font-family: "Charlie Display" !important;
  height: 100%;
  background-color: #f1f2f5;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}
main::-webkit-scrollbar {
  width: 0px;
  background: transparent;
}
/* REACT DATES CALENDAR FIX */
.DateInput_input__small {
  font-size: 14px !important;
  font-weight: 600 !important;
}
.SingleDatePicker_picker {
  z-index: 10;
}
.DateInput__small {
  width: 100% !important;
}
.DateInput__small > input {
  text-align: center;
  height: 30px !important;
  border-radius: 5px !important;
}
.DateInput_input__focused {
  border-bottom: 2px solid #e36a53 !important;
}
.CalendarDay__selected,
.CalendarDay__selected:active,
.CalendarDay__selected:hover {
  background: #e36a53 !important;
  border: 1px double #e36a53 !important;
  color: #fff;
  font-weight: bold !important;
}
.SingleDatePickerInput__withBorder {
  border-radius: 5px !important;
  border: 1px solid #dbdbdb;
}
.DateInput {
  border-radius: 5px !important;
}
/* JSON DEBUG FIX */
.__json-pretty__ {
  padding: 7px;
  border-radius: 5px;
}
/* VIRTUAL TABLE FIX */
.v-l {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  padding: 5px 10px;
  border-right: 1px solid #f0f0f0;
  border-bottom: 1px solid #f0f0f0;
}
.d-none {
  display: none;
}
.rce-mbox {
  padding: 6px 9px 22px 9px;
}
.rce-mbox-time {
  bottom: -9px !important;
}
.rce-container-citem {
  margin-bottom: 15px;
}
.rce-mbox.rce-mbox-right {
  overflow: hidden;
}
.rce-mbox-photo--img {
  cursor: pointer;
}
.rce-citem-avatar img {
  width: auto !important;
  height: auto !important;
}
.rce-container-citem {
  width: 350px;
}
.rce-citem {
  cursor: default !important;
}
.fc-event {
  margin-bottom: 3px;
  padding-left: 5px;
}
/* ANTD TABLE FIX */
.ant-table-row {
  cursor: pointer;
}
/* ANTD MODAL FIX */
.ant-modal-title {
  font-weight: 700 !important;
  text-align: center !important;
  font-size: large !important;
}
/* ANTD MENU FIX */
.ant-menu {
  background-color: transparent !important;
}
/* Estilo general del scrollbar */
.ant-dropdown-menu {
  max-height: 250px;
  overflow: auto;
  /* Oculta el scrollbar por defecto */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  scrollbar-width: none;
  /* Firefox */
  /* Añade una sombra a la derecha del scrollbar */
  box-shadow: 1px 0 5px rgba(0, 0, 0, 0.1);
}
/* Muestra el scrollbar cuando se hace hover sobre el componente */
.ant-dropdown-menu:hover {
  /* Muestra el scrollbar en Internet Explorer 10+ */
  -ms-overflow-style: auto;
  /* Muestra el scrollbar en Firefox */
  scrollbar-width: thin;
  scrollbar-color: #bebebe #f1f1f1;
}
/* Estilo personalizado del scrollbar */
::-webkit-scrollbar {
  width: 2px;
  background-color: #f1f1f1;
}
::-webkit-scrollbar-thumb {
  border-radius: 5px;
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
  background-color: #bebebe;
}
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}
.recharts-legend-item-text {
  font-weight: 600 !important;
}
.recharts-legend-wrapper {
  margin-top: 15px !important;
}
.recharts-default-legend {
  display: flex !important;
  justify-content: center !important;
  flex-direction: column !important;
}
.recharts-layer:focus {
  outline: none !important;
}
.recharts-sector:focus {
  outline: none !important;
}
