@font-face {
  font-family: "Charlie Display";
  src: url("CharlieDisplay-BlackItalic.woff2") format("woff2"),
    url("CharlieDisplay-BlackItalic.woff") format("woff");
  font-weight: 900;
  font-style: italic;
}

@font-face {
  font-family: "Charlie Display";
  src: url("CharlieDisplay-Regular.woff2") format("woff2"),
    url("CharlieDisplay-Regular.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Charlie Display";
  src: url("CharlieDisplay-LightItalic.woff2") format("woff2"),
    url("CharlieDisplay-LightItalic.woff") format("woff");
  font-weight: 300;
  font-style: italic;
}

@font-face {
  font-family: "Charlie Display";
  src: url("CharlieDisplay-Semibold.woff2") format("woff2"),
    url("CharlieDisplay-Semibold.woff") format("woff");
  font-weight: 600;
  font-style: normal;
}

@font-face {
  font-family: "Charlie Display";
  src: url("CharlieDisplay-Thin.woff2") format("woff2"),
    url("CharlieDisplay-Thin.woff") format("woff");
  font-weight: 100;
  font-style: normal;
}


@font-face {
  font-family: "Charlie Display";
  src: url("CharlieDisplay-Light.woff2") format("woff2"),
    url("CharlieDisplay-Light.woff") format("woff");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "Charlie Display";
  src: url("CharlieDisplay-Black.woff2") format("woff2"),
    url("CharlieDisplay-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "Charlie Display";
  src: url("CharlieDisplay-BoldItalic.woff2") format("woff2"),
    url("CharlieDisplay-BoldItalic.woff") format("woff");
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: "Charlie Display";
  src: url("CharlieDisplay-SemiboldItalic.woff2") format("woff2"),
    url("CharlieDisplay-SemiboldItalic.woff") format("woff");
  font-weight: 600;
  font-style: italic;
}

@font-face {
  font-family: "Charlie Display";
  src: url("CharlieDisplay-Italic.woff2") format("woff2"),
    url("CharlieDisplay-Italic.woff") format("woff");
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: "Charlie Display";
  src: url("CharlieDisplay-ThinItalic.woff2") format("woff2"),
    url("CharlieDisplay-ThinItalic.woff") format("woff");
  font-weight: 100;
  font-style: italic;
}

@font-face {
  font-family: "Charlie Display";
  src: url("CharlieDisplay-Bold.woff2") format("woff2"),
    url("CharlieDisplay-Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}
